import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ValidatorService {
    constructor() { }

    static emailValidator(control) {
        const value = control.value.split('/');
        if(value.length !== 2){
            return { 'InvalidCardExpDate': true }; 
        }
        const exMonth = value[0];
        const exYear = value[1];
        if(exYear.length !== 2){
            return { 'InvalidCardExpDate': true }; 
        }
        let today = new Date();
        let someday = new Date();
        const validYear = parseInt(`20${exYear}`);
        someday.setFullYear(validYear, exMonth, 1);
        if (someday > today) {
            return null;
        }else{
            return { 'InvalidCardExpDate': true };
        }
        
    }
    
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class ProgressService {
    constructor(private http: HttpClient) { }

    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    getHeadersForMultipart() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    create(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.progressUrl}/createProgress`, data, { headers: Headers });
    }

    getprogressList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.progressUrl}/getProgressListWeb`, { headers: Headers, params: { "id": obj.id, "page": obj.page } });
    }
    deleteProgress(progressId:any){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.progressUrl}/deleteProgress`, { headers: Headers, params: { "id": progressId} });
    }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        
        let verifyStripeUrl=request.url;
        // console.log("verifyStripeUrl",verifyStripeUrl.includes('https://files.stripe.com'));
        if(verifyStripeUrl.includes('https://files.stripe.com')==false){
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.token}`,
                    "Accept-language":'*',
                    //"Access-Control-Allow-Origin": "http://localhost:4200"
                }
            });
        }else{
            request = request.clone({
                setHeaders: {
                    "Accept-language":'*',
                    //"Access-Control-Allow-Origin": "http://localhost:4200"
                }
            });
        }

        }

        return next.handle(request);
    }
}
import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {Gym} from '../_models';
@Injectable({
  providedIn: 'root'
})
export class BrandcolorService {
  public currentGymSubject: BehaviorSubject<Gym>;
  public currentGym: Observable<Gym>;
private localUserObject;
  constructor(private http: HttpClient) {
    this.localUserObject = JSON.parse(localStorage.getItem('currentUser'));
    this.currentGymSubject = new BehaviorSubject<Gym>(this.localUserObject? this.localUserObject.gym[0] : null);
    this.currentGym = this.currentGymSubject.asObservable();
    
   }
   
   getHeaders() {
    var accessToken = localStorage.getItem('token');
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    //headers.append('authorization', 'bearer ' + accessToken);
    return headers;
}
  //  ApplyThemeProperties(){
  //    console.log(this.currentGymSubject.value.brandColor);
     
  //     const bodyStyles = document.body.style;
  //     bodyStyles.setProperty('--custom-background-color',"#000");
  //       bodyStyles.setProperty('--custom-calender-link-color',"#000")
  //       bodyStyles.setProperty('--custom-footer-follow-box-button-color',"#000")
  //  }

getBrandColorApi(id:any){
  var Headers = this.getHeaders();
  return this.http.get(`${environment.gymApiUrl}/getGym/${id}`, { headers: Headers });
}
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class SubscriptionPlansService {
    constructor(private http: HttpClient) { }
    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }


    createFileLink(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/createFileLink`, { headers: Headers ,params:obj});
        
    }

    validateCouponCode(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/validateCouponCode`, { headers: Headers ,params:obj});
    }
    
    getGymList() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.subscriptionPlansUrl}/getGymList`, { headers: Headers});
    }
    createReports(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/createReports`, { headers: Headers,params:obj});
    }
    retrieveReports(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/retrieveReports`, { headers: Headers,params:obj});
    }    
    getGymSubscriptionPlan(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.subscriptionPlansUrl}/getGymSubscriptionPlan`, { headers: Headers ,params:{"sortKey": obj.filter}});
    }


    getGymSubscriptionPlanNew(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.subscriptionPlansUrl}/getGymSubscriptionPlanNew`, { headers: Headers ,params:{"sortKey": obj.filter}});
    }
    getGymSubscriptionPlanGymOwnerSide(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/getGymSubscriptionPlanGymOwnerSide`, { headers: Headers ,params:{"sortKey": obj.filter, "userId": obj.userId}});
    }
    subscribeMonthlySubscription(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/subscribeMonthlySubscriptionNew`, { headers: Headers ,params:obj});
    }
    subscribeMonthlySubscriptionFromGymOwner(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/subscribeMonthlySubscriptionFromGymOwner`, { headers: Headers ,params:obj});
    }
    subscribeMonthlySubscriptionFromGymOwnerFree(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/subscribeMonthlySubscriptionFromGymOwnerFree`, { headers: Headers ,params:obj});
    }
    subscribeMonthlySubscriptionFromGymOwnerCash(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/subscribeMonthlySubscriptionFromGymOwnerCash`, { headers: Headers ,params:obj});
    }
    purchaseCreditPackage(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/purchaseCreditPackage`, { headers: Headers ,params:obj});
    }
    purchaseCreditPackageFromGymSide(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/purchaseCreditPackageFromGymSide`, { headers: Headers ,params:obj});
    }
    purchaseCreditPackageFromGymSideFree(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/purchaseCreditPackageFromGymSideFree`, { headers: Headers ,params:obj});
    }
    purchaseCreditPackageFromGymSideCash(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/purchaseCreditPackageFromGymSideCash`, { headers: Headers ,params:obj});
    }
    chargeCardForServices(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/chargeCardForServices`, { headers: Headers ,params:obj});
    }
    chargeForServicesCash(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/chargeForServicesCash`, { headers: Headers ,params:obj});
    }
    getMemberSubscription(){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/getMemberSubscription`, { headers: Headers});
    }
    checkExistingMonthlySubscription(){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/checkExistingMonthlySubscription`,{ headers: Headers});
    }
    getPlanDetails(plan_id) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/getPlanDetails`,{ headers: Headers,params:{"plan_id": plan_id}});
    }
    getPlanDetailsByGymOwner(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/getPlanDetailsByGymOwner`,{ headers: Headers,params:{"planId": obj.planId, userId: obj.userId}});
    }
    saveCreditCardDetails(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/saveCardDetails`,obj ,{ headers: Headers});
    }
    saveCardDetailsFromGymOwnerSide(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/saveCardDetailsFromGymOwnerSide`,obj ,{ headers: Headers});
    }
    updateCardDetails(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/updateCardDetails`,obj ,{ headers: Headers});
    }
    deleteCreditCard() {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/deleteCard`,{ headers: Headers});
    }
    deleteCardByGymOwner(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.subscriptionPlansUrl}/deleteCardByGymOwner/${id}`,{ headers: Headers});
    }
    
    
    subscribeFreeTrial(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/subscribeFreeTrial`,{ headers: Headers,params:obj});
    }
    cancelMonthlySubscription(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/cancelMonthlySubscription`,{ headers: Headers,params:obj});
    }
    cancelMonthlySubscriptionFromGymOwnerSide(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/cancelMonthlySubscriptionFromGymOwnerSide`,{ headers: Headers,params:obj});
    }
    cancelMonthlySubscriptionFromGymOwnerSideList(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/cancelMonthlySubscriptionFromGymOwnerSideList`,{ headers: Headers,params:obj});
    }
    getTermAndConditions() {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/getTermAndConditions`,{ headers: Headers});
    }


    cancelSubscriptionByGymOwner(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/cancelSubscriptionByGymOwner`,{ headers: Headers,params:obj});
    }
    refundPayment(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/refundPayment`,{ headers: Headers,params:obj});
    }



    updateSubscriptionByGymOwner(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/updateSubscriptionByGymOwner`,{ headers: Headers,params:obj});
    }

    pauseSubscriptionByGymOwner(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/pauseSubscriptionByGymOwner`,{ headers: Headers,params:obj});
    }
    unPauseSubscriptionByGymOwner(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/unPauseSubscriptionByGymOwner`,{ headers: Headers,params:obj});
    }


    
    
    
}

const protocol = 'https';
const domain = 'mail.vex-fitness.com';
const prefix = 'api';


export const environment = {
  production: true,
  environmentName: 'prod',

  api: `${protocol}://${domain}/${prefix}/`,

  //apiUrl: 'https://20tfo5ckd2.execute-api.us-west-1.amazonaws.com/prod/',
  apiUrl: 'https://20tfo5ckd2.execute-api.us-west-1.amazonaws.com/prod',
  gymApiUrl: "https://rnxqsblypj.execute-api.us-west-1.amazonaws.com/prod",

  eventApiUrl: 'https://yicc3imv02.execute-api.us-west-1.amazonaws.com/prod/v1',

  S3Url: 'https://vexfitnessuploads.s3.us-west-1.amazonaws.com/',

  eventUrl: 'https://yicc3imv02.execute-api.us-west-1.amazonaws.com/prod/v1',

  categoryUrl: 'https://o8tj5i8ui2.execute-api.us-west-1.amazonaws.com/prod/v1',

  trainerUrl: 'https://icn62jgw61.execute-api.us-west-1.amazonaws.com/prod/v1',

  videoUrl: 'https://o10tz8rjm9.execute-api.us-west-1.amazonaws.com/prod/v1',

  progressUrl: 'https://k62ms12bje.execute-api.us-west-1.amazonaws.com/prod/v1',

  planUrl: "https://qr9mfgrluj.execute-api.us-west-1.amazonaws.com/prod/v1",

  messageUrl: "https://5np263srg2.execute-api.us-west-1.amazonaws.com/prod/v1",

  subscriptionPlansUrl: "https://28xly4xrj2.execute-api.us-west-1.amazonaws.com/prod/v1",

  adminApiUrl: "https://7g7ze7atse.execute-api.us-west-1.amazonaws.com/prod/v1",

  stripeRedirect: "https://app.vex-fitness.com/dashboard/subscription-plans",

  stripeModuleUrl: "https://9de2588bzh.execute-api.us-west-1.amazonaws.com/prod/v1",

  ws_url: "https://mail.vex-fitness.com",
  vexAppUrl: "https://play.google.com/store/apps/details?id=com.vex.fitness",
  vexLiveAppUrl: "",
  stripePublicKey: "pk_live_bKus5NchHCvPNSvIIvombceo00NWa07Phl",

  terraDevId: 'vex-fitness-H64nqCttbC',
  terraXAPIKey: '2ffa3b5b6eab7ca48b3b6a52507f0f3263efcdf5b75402a129f09b270715d045'
};
﻿// import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// import { HomeComponent } from './home';
// import { LoginComponent } from './login';
// import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
// import { VerifyLinkComponent } from './verify-link/verify-link.component';
// import { AdduserprofileComponent } from './adduserprofile/adduserprofile.component';

// import { AboutusComponent } from './aboutus';
// import { RegisterComponent } from './register';
import { AuthGuard } from "./_guards";
import { ContactUsComponent } from "./contact-us/contact-us.component";

// import { AdminComponent } from './admin/admin.component';
// import { AutologinComponent } from './autologin/autologin.component';
import { CustomLoadStrategyService } from "./_services/custom-load-strategy.service";
import { HrStreamingComponent } from "./theme/eventAttendies/hr-streaming/hr-streaming.component";

const appRoutes: Routes = [
  // { path: '', component: HomeComponent, pathMatch:'full' },
  // { path: 'aboutus', component: AboutusComponent },
  // { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  // { path: 'resetPassword', component: ResetpasswordComponent },
  // { path: 'verifyEmail', component: VerifyLinkComponent },
  // { path: 'completeprofile', component: AdduserprofileComponent },
  // { path: 'autologin', component: AutologinComponent },
  // { path: 'addchildren', component: AddchildrenComponent },
  // {
  //     path: 'admin',
  //     component: AdminComponent,
  //     // loadChildren: 'app/adminlayout/adminlayout.module#AdminlayoutModule',
  // },

  {
    path: "",
    loadChildren: "app/home/home.module#HomeModule",
    data: { preload: true },
    pathMatch: "full",
  },
  {
    path: "aboutus",
    loadChildren: "app/aboutus/aboutus.module#AboutusModule",
    data: { preload: false },
  },
  {
    path: "login",
    loadChildren: "app/login/login.module#LoginModule",
    data: { preload: true },
  },
  {
    path: "count-down/:id",
    loadChildren: "app/count-down/count-down.module#CountDownModule",
    data: { preload: true },
  },
  {
    path: "login/:id",
    loadChildren: "app/memberlogin/memberlogin.module#MemberLoginModule",
    data: { preload: true },
  },
  {
    path: "register",
    loadChildren: "app/register/register.module#RegisterModule",
    data: { preload: true },
  },
  {
    path: "resetPassword",
    loadChildren: "app/resetpassword/resetpassword.module#ResetpasswordModule",
    data: { preload: false },
  },
  {
    path: "verifyEmail",
    loadChildren: "app/verify-link/verify-link.module#VerifyLinkModule",
    data: { preload: false },
  },
  {
    path: "stripeForm",
    loadChildren: "app/stripe-form/stripe-form.module#StripeFormModule",
    data: { preload: false },
  },
  {
    path: "completeprofile",
    loadChildren:"app/adduserprofile/adduserprofile.module#AdduserprofileModule",
    data: { preload: false },
  },
  {
    path: "autologin",
    loadChildren: "app/autologin/autologin.module#AutologinModule",
    data: { preload: false },
  },

  {
    path: "admin",
    loadChildren: "app/admin/admin-login.module#AdminLoginModule",
    data: { preload: false },
  },
  {
    path: "dashboard",
    data: { preload: false },
    loadChildren: "app/theme/theme.module#ThemeModule",
    canActivate: [AuthGuard],
  },

  {
    path: "customers",
    data: { preload: false },
    loadChildren:"app/customerlayout/customerlayout.module#CustomerlayoutModule",
    canActivate: [AuthGuard],
  },
  // {
  //   path: "single-news-feed",
  //   data: { preload: false },
  //   loadChildren:"app/customerlayout/single-news-feed",
  //   canActivate: [AuthGuard],
  // },
  { path: "chat", component: ContactUsComponent },
  {
    path: "adminDashboard",
    data: { preload: false },
    loadChildren: "app/adminlayout/adminlayout.module#AdminlayoutModule",
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard/event-attendies/hr-streaming/:id",
    component: HrStreamingComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: CustomLoadStrategyService,
    }),
    //  HttpClientModule
  ],
  exports: [
    RouterModule,
    // HttpClientModule
  ],
})
export class AppRoutingModule {}

import { DecimalPipe, Location } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CountdownComponent } from '@ciri/ngx-countdown';
import * as moment from "moment";
import {
  BehaviorSubject,
  Subscription,
  timer
} from "rxjs";
import { first } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import {
  EventService,
  GymService
} from "../../../_services";
import { Attendant, LongTermMemoryEntry, User, getAgeInYears, getCalories, getCurrentDate, getTimer, getWeightInKg, isJsonString, transform } from "./utils";

declare var io: any; // declare the io variable

@Component({
  selector: "app-hr-streaming",
  templateUrl: "./hr-streaming.component.html",
  styleUrls: ["./hr-streaming.component.css"],
})

export class HrStreamingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('countdown') private countdownComponent: CountdownComponent;

  private readonly subs = new Subscription();

  @HostListener("window:unload", ["$event"])
  unloadHandler(event) {
    this.emitCalories();
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event) {
    this.emitCalories();
  }

  show = false;
  socket: any; // declare the socket variable
  eventId: string;
  startTime: any;
  nextClassTime: any;
  currentEvent: any;
  nextEvent: any;
  classCode: any = "";
  gymLogo =
    environment.S3Url + JSON.parse(localStorage.getItem("currentUser")).gymlogo;
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  socketUrl = environment.ws_url;
  usersInClass: Attendant[] = [];
  loading = false;
  subject = new BehaviorSubject<any>(0);
  allConnectedUsers: string[] = [];
  usersData: User[] = [];
  heatRateBlock: boolean = true;
  currentDate: number;
  eventList: any[] = [];
  nextEventStartDate: any;
  private timer1Subscription: Subscription;
  private timer2Subscription: Subscription;

  timer: any = "00:00";
  totalTime: any = "00:00";
  percentage: number = 0;
  timerSeconds: number = 0;
  totalSeconds: number = 0;
  timerActive: boolean = false;
  currentRound: number = 0;
  rounds: number = 1;
  isRestTimer: boolean;
  totalRestMilliSeconds: number = 0;
  restMinutes: number = 0;
  restSeconds: number = 1;
  totalRemainingSeconds: number;
  actionFlag: boolean;
  action: string;
  remainingTime: number;
  currentTimeStamp: number;
  totalMilliSeconds: number;
  tabLeaveTime: any;
  totalTimePerRound: any;
  totalTimerTime: any;
  countdownInSeconds = 10;
  showCountDown: boolean = false;
  removeListUser: any = [];
  longTermMemory: Record<string, LongTermMemoryEntry> = {};
  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private eventService: EventService, // private webSocketService: WebsocketService
    private gymService: GymService,
    private cd: ChangeDetectorRef,
    private decimalPipe: DecimalPipe
  ) {

    this.socket = io(this.socketUrl); // create a new Socket.IO instance
    setTimeout(() => {
      this.socket.emit("chat", "start stream");
      this.socket.emit("timer", { gymId: this.currentUser.GymId });
    }, 10000);

    this.setupSocketListeners();
  }

  async ngOnInit() {
    this.currentDate = getCurrentDate();
    this.getEventList();
    this.route.params.subscribe((params) => {
      this.eventId = params["id"];
      this.eventInfo();
    });

    this.timer1Subscription = timer(12000, 6000).subscribe(() => {
      this.currentDate = getCurrentDate();
      this.nextEventStartDate = this.getNextEventStartTime();
      this.removeInactiveUsers();
      this.removeLongTermMemory();
      this.getUpdatedStreamUsersSocket();
    });

    this.timer2Subscription = timer(
      0,
      1000
    ).subscribe(() => {
      this.eventList && this.checkTimeMatches();
    });

    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.tabLeaveTime = moment();
      } else {
        this.socket.emit("timer", { gymId: this.currentUser.GymId });
        const currTime = moment();
        const backToScreenTime = currTime.diff(this.tabLeaveTime, "seconds");
        this.totalTimerTime = this.totalTimerTime - backToScreenTime;
        if (this.totalTimerTime < 0) {
          this.countdownComponent.time = 0;
          this.currentRound = 0;
        }
        if (this.action !== 'stop' && this.totalTimerTime > 0) {
          const a = this.totalTimerTime / this.totalTimePerRound ? Math.floor(this.totalTimerTime / this.totalTimePerRound) : 0; //230/70=3.3 =3
          this.currentRound = a;
          const b = a * this.totalTimePerRound ? a * this.totalTimePerRound : 0;
          const c = this.totalTimerTime - b ? this.totalTimerTime - b : 0;

          if (c > this.totalMilliSeconds / 1000) {
            const d = c - this.totalMilliSeconds / 1000 ? c - this.totalMilliSeconds / 1000 : 0;
            this.isRestTimer = true;
            this.countdownComponent.time = d * 1000;
          } else {
            this.isRestTimer = false;
            this.countdownComponent.time = c * 1000;
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    this.percentage = 0;
    if (this.countdownComponent) {
      this.countdownComponent.tick.subscribe((value) => {
        let totalRemainingSeconds = value / 1000;
        this.totalTimerTime--;
        this.totalRemainingSeconds = totalRemainingSeconds;
        this.timerSeconds = Math.floor(this.totalSeconds - totalRemainingSeconds);
        this.percentage = (this.timerSeconds / this.totalSeconds) * 100;

        if (this.currentRound > 0 && totalRemainingSeconds === 0) {
          this.timerActive = true;
          if (this.isRestTimer) {

            this.countdownComponent.time = this.totalSeconds * 1000;
            this.isRestTimer = false;
            this.currentRound--;
            // if (this.currentRound === 0)
            // {
            //   console.log("coming here")
            // }

          } else if (!this.isRestTimer && this.currentRound !== 0) {
            this.countdownComponent.time = this.totalRestMilliSeconds;
            this.isRestTimer = true;
          }
        }

        // if (this.currentRound === 0 && Math.floor(totalRemainingSeconds) === 0)
        // {
        //   this.countdownComponent.time = 0;
        //   this.timerActive = false;
        //   this.percentage = 0;
        //   // this.timerActive = false;
        // }

      });
    }
  }

  setupSocketListeners() {
    this.socket.on("chat", this.handleChatMessage.bind(this));
    this.socket.on(`countDown-${this.currentUser.GymId}`, this.handleCountDown.bind(this));
    this.socket.on("getUsers", (res: { data: User[] }) => {
      this.processUserData(res["data"]);
    });
  }

  handleChatMessage(message: any) {
    if (isJsonString(message)) {
      message = JSON.parse(message);
    }

    if (message["op"] == 5) {
      console.log(message);

      message["d"]["val"] = parseInt(message["d"]["val"]);
      message["d"]["val"] = Math.floor(message["d"]["val"]);

      const uid = message["uid"];

      if (!this.allConnectedUsers.includes(uid) 
        || !this.usersData.find(user => user.terraUserId === uid) 
        || !this.usersInClass.find(user => user.user.terraUserId === uid)) {
        this.allConnectedUsers = Array.from(new Set([...this.allConnectedUsers, uid]));
        this.getUpdatedStreamUsersSocket();
      }

      this.usersInClass.forEach((user) => {
        if (uid === user.user.terraUserId) {

          // Long term memory is to ensure we don't lose track of calories when connection drops
          this.longTermMemory[user.user.email] = this.longTermMemory[user.user.email] || {
            heartBeatStream: [],
            startTime: moment().unix(),
            lastCalorieUpdate: 0,
            providesCalories: false
          };
          this.longTermMemory[user.user.email].lastEmit = moment().unix();

          if (message["t"].includes("CALORIE")) {
            user.calories = message["d"]["val"];
            this.longTermMemory[user.user.email].lastCalorieUpdate = moment().unix();
            this.longTermMemory[user.user.email].providesCalories = true;
          }
          
          if (message["t"].includes("HEART_RATE")) {
            user.heartBeat = message["d"]["val"];
            user.heartBeatStream.push(user.heartBeat);
            this.longTermMemory[user.user.email].heartBeatStream.push(user.heartBeat);
            
            const lastCalorieUpdate = this.longTermMemory[user.user.email].lastCalorieUpdate || 0;
            const timeSinceLastCalorieUpdate = moment().unix() - lastCalorieUpdate;
            
            // Calculate calories if the device doesn't provide them or if it's been too long since the last calorie update
            if (!this.longTermMemory[user.user.email].providesCalories || timeSinceLastCalorieUpdate > 30) { // 30 seconds threshold
              user.calories = getCalories(user, this.longTermMemory[user.user.email]);
            }
          }

          user.lastEmit = moment().unix();
          user.percentage = Math.floor((user.heartBeat / 200) * 100);

          if (!user.heartBeat) {
            user.color = '#B0B0B0'; // Grey for no heartbeat
          } else if (user.heartBeat < 95) {
            user.color = "#B0B0B0"; // Zone 0: Grey
          } else if (user.heartBeat >= 95 && user.heartBeat <= 113) {
            user.color = "#0077BE"; // Zone 1: Blue
          } else if (user.heartBeat >= 114 && user.heartBeat <= 132) {
            user.color = "#00B050"; // Zone 2: Green
          } else if (user.heartBeat >= 133 && user.heartBeat <= 151) {
            user.color = "#E5B800"; // Zone 3: Yellow
          } else if (user.heartBeat >= 152 && user.heartBeat <= 170) {
            user.color = "#FF6A00"; // Zone 4: Orange
          } else if (user.heartBeat >= 171) {
            user.color = "#D90000"; // Zone 5: Red
          }
        }
      });
    }
  }

  handleCountDown(res: any) {
    const hrCurrentTimeStamp = moment().unix();
    const timeDiff = hrCurrentTimeStamp - res.currentTimeStamp;
    if (res.action === "start" && !res.actionFlag && timeDiff < 10 && !this.showCountDown) {
      this.showCountDown = true;
      const countdownInterval = setInterval(async () => {
        if (this.countdownInSeconds > 1) {
          this.countdownInSeconds--;
        } else {
          this.showCountDown = false;
          clearInterval(countdownInterval);
          this.startCountDownTimer(res);
        }
      }, 1000);
      return false;
    } else {
      this.startCountDownTimer(res);
    }
  }

  startCountDownTimer(res: any) {
    this.currentRound = res.currentRound;
    this.rounds = res.rounds;
    this.totalRestMilliSeconds = res.totalRestMilliSeconds;
    this.isRestTimer = res.isRestTimer;
    this.remainingTime = res.remainingTime;
    this.action = res.action;
    this.totalTimePerRound = res.minutes * 60 + res.seconds + (res.restMinutes * 60 + res.restSeconds);
    this.totalTimerTime = (res.totalMilliSeconds * res.rounds + res.totalRestMilliSeconds * (res.rounds - 1)) / 1000;

    if (!res.actionFlag) {
      this.timerActive = true;
      if (res.remainingTime <= 0) {
        this.percentage = 100;
      }
      const hrCurrentTimeStamp = moment().unix();
      const timeDiff = hrCurrentTimeStamp - res.currentTimeStamp;

      res.remainingTime = res.remainingTime - timeDiff * 1000;
      this.countdownComponent.time = res.remainingTime;
      this.totalSeconds = res.totalMilliSeconds / 1000;
      const TSeconds = Math.floor(res.totalMilliSeconds / 1000);
      const minutes = Math.floor(TSeconds / 60);
      const seconds = TSeconds % 60;
      this.totalTime = getTimer(minutes, seconds);

    } else if (res.actionFlag && res.action === 'stop') {

      this.timerActive = true;
      // this.countdownComponent.time =  !res.isRestTimer ? (Math.ceil(res.remainingTime / 1000) * 1000) : res.totalRestMilliSeconds ;
      this.countdownComponent.time = (Math.ceil(res.remainingTime / 1000) * 1000);
      this.countdownComponent.pause();


    } else if (res.actionFlag && res.action === 'resume') {
      this.timerActive = true;
      this.countdownComponent.start();

    } else if (res.actionFlag && res.action === 'reset') {
      this.timerActive = true;
      this.percentage = 0;
      this.countdownComponent.reset();

    } else if (res.actionFlag && res.action === 'finish') {
      this.timerActive = false;
      this.countdownComponent.time = res.totalMilliSeconds;
      this.totalSeconds = 0;
      this.percentage = 0;
      this.countdownInSeconds = 10
    }
    console.log(this.countdownComponent.time)
  }

  isTimerStart() {
    return this.timerActive && this.countdownComponent.time !== undefined;
  }

  getUpdatedStreamUsersSocket() {
    this.socket.emit("getUpdatedStreamUsers", {
      terraUsers: this.allConnectedUsers,
      eventId: this.eventId,
    });
  }

  processUserData(users: User[]) {
    this.usersData = [...(users || [])];
    console.log('Got user data', this.usersData)
    this.usersData.forEach(user => {
      user.weightInKg = getWeightInKg(user.weight, user.weightUnit);
      user.ageInYears = getAgeInYears(user.dob);

      let existingUser = this.usersInClass.find((item: Attendant) => item.user.email === user.email);

      if (!existingUser) {
        this.usersInClass.push({
          user: user,
          startTime: moment().unix(),
          heartBeatStream: [],
          lastMinute: 0,
        });
      }
      if (!this.allConnectedUsers.includes(user.terraUserId)) {
        this.allConnectedUsers.push(user.terraUserId);
      }
    });

    this.usersInClass = this.usersInClass.filter((item: Attendant) =>
      this.usersData.some(user => user.email === item.user.email)
    );

    this.usersInClass = Array.from(
      new Set(this.usersInClass.map((item: Attendant) => item.user.email))
    ).map(email =>
      this.usersInClass.find((item: Attendant) => item.user.email === email)
    );
  }

  getNextEventStartTime() {
    // return this.nextEvent[0].startDate && moment.unix(this.nextEvent[0].startDate);
    return this.nextEvent[0]["startDate"];
  }

  getEventList() {
    this.eventService
      .eventAttendeesList({
        page: 1,
        startDate: this.currentDate,
        filter: "upcoming",
        search: "",
        searchDatestart: "",
        searchDateEnd: "",
        offset: -10800000,
      })
      .pipe(first())
      .subscribe(
        (res) => {
          this.eventList = res["data"].eventList;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  checkTimeMatches() {
    if (!this.eventList) return;

    const timeNow = moment();

    if (this.nextEventStartDate) {
      const someAgo = moment
        .unix(this.nextEventStartDate)
        .subtract(10, "minutes");
      const nextEventCondition =
        someAgo.isSame(timeNow, "second") &&
        this.eventId !== this.nextEvent[0]._id;

      if (nextEventCondition) {
        this.router.navigate([
          "dashboard/event-attendies/hr-streaming",
          this.nextEvent[0]._id,
        ]);
      }
    }

    for (const event of this.eventList) {
      const startDate = event["startDate"] && moment.unix(event["startDate"]);

      const startCondition =
        startDate &&
        startDate.isSame(timeNow, "second") &&
        this.eventId !== event._id;

      if (startCondition) {
        this.router.navigate([
          "dashboard/event-attendies/hr-streaming",
          event._id,
        ]);
      }
    }
  }

  eventInfo() {
    this.loading = true;
    this.eventService
      .getEventInfo(this.eventId)
      .pipe(first())
      .subscribe(
        (res) => {
          this.currentEvent = res["data"]["eventDetail"];

          this.getAllAttendeesList(this.currentEvent._id);

          this.nextEvent = res["data"]["nextEvent"];

          let diff, formattedDiff, currentTime, currentClassTime, nextClassTime;

          this.nextClassTime = this.nextEvent[0].startDate
            ? moment(this.nextEvent[0].startDate * 1000).format("LTS")
            : null;

          this.nextClassTime &&
            timer(0, 1000).subscribe(() => {
              currentTime = moment();
              // Unix timestamp

              // Convert Unix timestamp to Moment object
              currentClassTime = moment.unix(this.currentEvent.startDate);
              nextClassTime = moment.unix(this.nextEvent[0].startDate);

              // Calculate the time difference

              diff = moment.duration(currentTime.diff(currentClassTime));

              // Format the time difference as 'HH:mm:ss'
              formattedDiff = `${Math.abs(Math.floor(diff.asHours()))
                .toString()
                .padStart(2, "0")}:${Math.abs(diff.minutes())
                  .toString()
                  .padStart(2, "0")}:${Math.abs(diff.seconds())
                    .toString()
                    .padStart(2, "0")}`;

              this.startTime = formattedDiff;

              diff = moment.duration(nextClassTime.diff(currentTime));

              // Format the time difference as 'HH:mm:ss'
              formattedDiff = `${Math.abs(Math.floor(diff.asHours()))
                .toString()
                .padStart(2, "0")}:${Math.abs(diff.minutes())
                  .toString()
                  .padStart(2, "0")}:${Math.abs(diff.seconds())
                    .toString()
                    .padStart(2, "0")}`;
              this.nextClassTime = formattedDiff;
            });

          try {
            this.heatRateBlock =
              this.currentEvent.gymId.gymSetting.heartBeatInPercentage;
          } catch (error) {
            console.log(error);
          }

          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  getAllAttendeesList(id) {
    this.loading = true;
    this.eventService
      .getAllAttendeesListV2({ eventId: id })
      .pipe(first())
      .subscribe((res) => {
        this.classCode = res["data"]["classCode"];
        this.loading = false;
      });
    (error) => {
      this.loading = false;
    };
  }

  toggleMenu() {
    this.show = !this.show;
  }

  toggleHeartRate() {
    this.loading = true;
    this.heatRateBlock = !this.heatRateBlock;
    this.gymService
      .updateGymSetting({ heartBeatInPercentage: this.heatRateBlock })
      .pipe(first())
      .subscribe(
        (res) => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          // this.error = error;
        }
      );
  }

  exitStream() {
    try {
      this.router.navigate(["/dashboard/event-attendies"]);
    } catch (error) {
      this.router.navigate(["/dashboard/event-attendies"]);
      console.log(error);
    }
  }

  transform = transform;

  removeLongTermMemory() {
    const currentTime = moment().unix();
    const removedUsers = [];

    for (const email in this.longTermMemory) {
      const lastEmitTime = this.longTermMemory[email].lastEmit || 0;
      const isActive = (currentTime - lastEmitTime) <= 60 * 5; // 5 minutes
      if (!isActive) {
        removedUsers.push(email);
        delete this.longTermMemory[email];
      }
    }

    if (removedUsers.length) {
      console.log(removedUsers, "removed inactive users from long term memory");
    }
  }

  removeInactiveUsers() {
    const currentTime = moment().unix();
    let removedUsers = [];

    this.usersInClass = this.usersInClass.filter((user) => {
      const lastEmitTime = user.lastEmit || 0;
      const isActive = (currentTime - lastEmitTime) <= 10;
      console.log(user.user.terraUserId, isActive, currentTime, lastEmitTime, currentTime - lastEmitTime)
      if (!isActive) {
        removedUsers.push(user);
      }

      return isActive;
    });

    if (removedUsers.length) {
      console.log(removedUsers, "removed inactive users");
      this.emitCalories(removedUsers, 'removeInactiveUsers');

      this.socket.emit('removeInactiveUsers', {
        removedUsers,
        eventId: this.eventId,
      });

      // After:
      // removedUsers.forEach((userData) => {
      //   this.eventService
      //     .stopStream({
      //       class_code: this.classCode,
      //       user_id: userData.user._id,
      //     })
      //   });
    }
  }

  emitCalories(attendentData: Attendant[] = this.usersInClass, type: string = "upload") {
    const updatedUserCalories = attendentData.map((attendent) => {
      return { userId: attendent.user._id, calories: attendent.calories };
    });

    this.socket.emit("updateCalories", {
      terraUsers: updatedUserCalories,
      eventId: this.eventId,
      classCode: this.classCode,
    });
  }


  ngOnDestroy() {
    document.removeEventListener("visibilitychange", () => {
      // Clean up any resources if needed
    });
    this.subs.unsubscribe();
    if (this.timer1Subscription) {
      this.timer1Subscription.unsubscribe();
    }

    if (this.timer2Subscription) {
      this.timer2Subscription.unsubscribe();
    }

    const updatedUserCalories = this.usersInClass.map((attendent) => {
      return { userId: attendent.user._id, calories: attendent.calories };
    });

    this.socket.emit("updateCalories", {
      terraUsers: updatedUserCalories,
      eventId: this.eventId,
      classCode: this.classCode,
    });

    this.socket.close();
  }
}
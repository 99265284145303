import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class CategoryService {


    customers: any = [];
    bkpCustomers: any = [];
    searchInput: any = "";



    constructor(private http: HttpClient) { }



    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    getHeadersForMultipart() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }
    
    create(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.categoryUrl}/create`, data, { headers: Headers });
    }

    

    getCatList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.categoryUrl}/categoryList`, { headers: Headers, params: { "search": obj.search, "page": obj.page } });
    }

    getLocationList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.categoryUrl}/locationList`, { headers: Headers, params: { "search": obj.search, "page": obj.page } });
    }

    getAllLocationList() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.categoryUrl}/allLocationList`, { headers: Headers});
    }

    getAllLocationListByUser() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.categoryUrl}/allLocationListByUser`, { headers: Headers});
    }

    

    

    getCatDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.categoryUrl}/getCategory/${id}`, { headers: Headers });
    }

    getLocationDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.categoryUrl}/getLocation/${id}`, { headers: Headers });
    }
    
    update(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.categoryUrl}/updateCategory`, data, { headers: Headers });
    }

    updateLocation(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.categoryUrl}/updateLocation`, data, { headers: Headers });
    }

    getCategory() {
        var Headers = this.getHeaders();
       // return this.http.get(`${environment.categoryUrl}/getCategoryList`, { headers: Headers, params: { "search": search } });
        return this.http.get(`${environment.categoryUrl}/getCategoryList`, { headers: Headers });
    }
    

    getWebCat() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.categoryUrl}/getWebCategories`, { headers: Headers });
    }


    createLocation(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.categoryUrl}/createLocation`, data, { headers: Headers });
    }

    gymLogo(id) {
        return this.http.get(`${environment.categoryUrl}/gymLogo/${id}`);
    }

    userDetail() {
        this.customers = this.bkpCustomers;
        // var Headers = this.getHeaders();
        // return this.http.post(`${environment.categoryUrl}/userDetail`, data, { headers: Headers });
        
        this.searchInput = this.searchInput.toLowerCase();
        this.customers = this.customers.filter((customer) => {
            return customer.userData.firstName.toLowerCase().includes(this.searchInput) || (customer.userData.email.toLowerCase().includes(this.searchInput));
        });
        console.log(this.customers, this.bkpCustomers, this.searchInput);
    }

    


}

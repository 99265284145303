import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input() percentage: number = 0;
  getBarOpacity(index: number): number {
    const barsCount = 16;
    const filledBarsCount = Math.floor((this.percentage / 100) * barsCount);
    const isFirstBar = index === 0;
  
    if (this.percentage === 0) {
      return 0.4;
    } else if (this.percentage === 100 && isFirstBar) {
      return 1;
    } else if (index < filledBarsCount || this.percentage >= 100) {
      return 1;
    } else {
      return 0.4;
    }
  }

}

﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class MessageService {
    constructor(private http: HttpClient) { }


    getMessageList(Id:string) {
        let postjson = {
            "_id": Id,
            "isfilter": true
        }

        return this.http.post(`${environment.apiUrl}/message/list`, postjson);
    }

    getMessageListDirector(Id:string) {
        let postjson = {
            "_id": Id,
            "isfilter": true
        }

        return this.http.post(`${environment.apiUrl}/message/directorList`, postjson);
    }

    getConversationList(Id:string) {
        let postjson = {
            "_id": Id,
            "isfilter": true
        }

        return this.http.post(`${environment.apiUrl}/message/directorConversationList`, postjson);
    }

    saveMessage(obj) {
        return this.http.post(`${environment.apiUrl}/message/create`, obj);
    }

    saveMessageDirector(obj) {
        
        return this.http.post(`${environment.apiUrl}/message/directorCreate`, obj);
    }


    createMessage(obj) {
        return this.http.post(`${environment.apiUrl}/message/save`, obj);
    }

    createMessageDirector(obj) {
        
        return this.http.post(`${environment.apiUrl}/message/directorSave`, obj);
    }


    getSingleMessagesList(from,to) {
        let postjson = {
            "from": from,
            "to":to
        }

        return this.http.post(`${environment.apiUrl}/message/find`, postjson);
    }

    getSingleMessagesListDirector(from,to) {
        let postjson = {
            "from": from,
            "to":to
        }

        return this.http.post(`${environment.apiUrl}/message/directorFind`, postjson);
    }

    getSingleMessagesConversationList(thread) {
        let postjson = {
            "thread": thread
        }

        return this.http.post(`${environment.apiUrl}/message/conversationFind`, postjson);
    }
    
 
}

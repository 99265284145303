﻿import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './_services';
import { User } from './_models';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    currentUser: User;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        if (this.authenticationService.currentUserValue) {
            // if (localStorage.getItem('role') == 'user') {
            //     this.router.navigate(['/customers']);
            // } else if (localStorage.getItem('role') == 'gym') {
            //     this.router.navigate(['/dashboard']);
            // }
        }
    }
    //isLoggedIn;
    //constructor(private admin: AdminService) {
    //    this.isLoggedIn = this.admin.getUserLoggedIn();
    //    this.checkAdminLogin();
    //    console.log(this.admin.getUserLoggedIn());
    //    this.isLoggedIn = true;
    //}

    //ngOnInit() { }

    //title = 'app';


    //checkAdminLogin() {
    //    this.admin.getUserLoggedIn();
    //    // console.log(this.admin.getUserLoggedIn());
    //}
   
}
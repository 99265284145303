import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import * as Sentry from "@sentry/angular";

// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from "@angular/platform-browser";
// import { NgxMaskModule } from 'ngx-mask';

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
// import { ConfirmEqualValidatorDirective } from './directive/confirm-equal-validator.directive';
// import { HomeComponent } from './home';
// import { AboutusComponent } from './aboutus';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import {
  NgbModule,
  // NgbPaginationModule
} from "@ng-bootstrap/ng-bootstrap";
import { NgxLoadingModule } from "ngx-loading";
// import { LoginComponent } from './login';
// import { RegisterComponent } from './register';
import {
  AlertComponent,
  // HeaderComponent
} from "./_components";
import {
  ErrorInterceptor,
  fakeBackendProvider,
  JwtInterceptor,
} from "./_helpers";
// import { AdminComponent } from './admin/admin.component';
// import { AutocompleteLibModule } from 'angular-ng-autocomplete';
// import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
// import { VerifyLinkComponent } from './verify-link/verify-link.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import * as moment from 'moment'
// import { AdduserprofileComponent } from './adduserprofile/adduserprofile.component';
// import { NgxPaginationModule } from 'ngx-pagination';
//import { NgxPayPalModule } from 'ngx-paypal';
// import { YouTubePlayerModule } from "@angular/youtube-player";
// import { AngularEditorModule } from '@kolkov/angular-editor';
// import { NgxSmartModalModule } from 'ngx-smart-modal';
// import { AutologinComponent } from './autologin/autologin.component';
import { SharedService } from "../app/_services/shared.service";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { HeadersComponent } from "./customerlayout/headers/headers.component";
import { ChatService } from "./_services/hr-streaming-socket.service";
import { WebsocketService } from "./_services/websocket.service";
import { HrStreamingComponent } from "./theme/eventAttendies/hr-streaming/hr-streaming.component";
import { NgCircleProgressModule } from 'ng-circle-progress';
import {DecimalPipe} from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ProgressBarComponent } from './theme/eventAttendies/progress-bar/progress-bar.component';
import { CountdownModule } from '@ciri/ngx-countdown'
import { Router } from "@angular/router";

@NgModule({
  imports: [
    // NgxPaginationModule,
    BrowserModule,
    // ReactiveFormsModule,
    // FormsModule,
    NgbModule,
    HttpClientModule,
    // AngularEditorModule,
    // NgxSmartModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    // NgxMaskModule.forRoot(),
    AppRoutingModule,
    // DeviceDetectorModule.forRoot(),
    // AutocompleteLibModule,
    BrowserAnimationsModule,
    // YouTubePlayerModule,
    //  NgxPayPalModule
    NgCircleProgressModule.forRoot({
      // set defaults here
      
        backgroundColor: "#ffffff",
        backgroundOpacity: 1,
        backgroundPadding: 7,
        radius: 60,
        space: -5,
        outerStrokeWidth: 3,
        innerStrokeColor: "#b7b3b3",
        innerStrokeWidth: 2,
        title:"here",
        titleFontSize: "15",
        subtitleFontSize: "20",
        imageHeight: 20,
        animateTitle: false,
        animationDuration: 1000,
        showUnits: false,
        startFromZero: false,
        titleColor:"#6b6666",
        subtitleColor:"#ffffff",
        outerStrokeColor:"#FDB900",
        outerStrokeLinecap:"square"
        
    }),
    MatProgressBarModule,
    CountdownModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    ContactUsComponent,
    HrStreamingComponent,
    // HomeComponent,
    // AboutusComponent,
    // LoginComponent,
    // RegisterComponent,
    // HeaderComponent,
    // ConfirmEqualValidatorDirective,
    // AdminComponent,
    // ResetpasswordComponent,
    // VerifyLinkComponent,
    // AdduserprofileComponent,
    // AutologinComponent
    ProgressBarComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    DecimalPipe,
    ChatService, WebsocketService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    
    fakeBackendProvider,
    SharedService,
    HeadersComponent,
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

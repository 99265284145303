
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class EventService {
    constructor(private http: HttpClient) { }

    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    getHeadersForMultipart() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    createEvent(obj) {
        var Headers = this.getHeaders();

        return this.http.post(`${environment.eventApiUrl}/create`, obj, { headers: Headers });
    }

    updateEvent(obj) {
        var Headers = this.getHeaders();

        return this.http.put(`${environment.eventApiUrl}/updateEvent`, obj, { headers: Headers });
    }

    getLiveEvents(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.eventUrl}/getHomeLiveEventsNew`, { headers: Headers, params: { "startDate": obj.startDate } });
    }

    getAllEvents(obj) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/eventList`, { headers: Headers, params: { "startDate": obj.startDate, "sortKey": obj.filter, "search": obj.search, "eventType":obj.eventType, "time":obj.time,"days":obj.days, "page": obj.page } });
    }

    getUserEvents(obj) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/eventList`, { headers: Headers, params: { "startDate": obj.startDate, "endDate": obj.endDate } });
    }

    getEventDetail(val) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/viewEventDetails/${val}`, { headers: Headers });
    }

  
    


    calendarData(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.eventUrl}/getScheduleEventsNew`, { headers: Headers, params: { "startDate": obj.startDate, "endDate": obj.endDate, "page": obj.page, "type": obj.type, "offset": obj.offset, "location": obj.location, "typeEvent":obj.typeEvent } });
    }

    getEventAttendies(obj) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/getEventAttendies`, { headers: Headers, params: { "id": obj.id, "page": obj.page } });
    }

    getPayments(obj) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/getGymPayments`, { headers: Headers, params: { "startDate": obj.startDate, "sortKey": obj.filter, "search": obj.search, "page": obj.page } });
    }


    joinEvent(obj) {
        var Headers = this.getHeaders();

        return this.http.post(`${environment.eventApiUrl}/joinEvent`, obj, { headers: Headers });
    }
    chargeCard(obj) {
        var Headers = this.getHeaders();

        return this.http.post(`${environment.eventApiUrl}/chargeCard`, obj, { headers: Headers });
    }

    getEvent(val) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/getEvent/${val}`, { headers: Headers });
    }
    getEventInfo(val) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/getEventClass/${val}`, { headers: Headers });
    }

    deleteEvent(val) {
        var Headers = this.getHeaders();

        return this.http.delete(`${environment.eventUrl}/deleteEvent/${val}`, { headers: Headers });
    }
    removeMember(val) {
        var Headers = this.getHeaders();

        return this.http.delete(`${environment.eventUrl}/removeMember/${val}`, { headers: Headers });
    }
    removeTrainer(val,trainerId) {
        var Headers = this.getHeaders();

        return this.http.delete(`${environment.eventUrl}/removeTrainer/${val}/${trainerId}`, { headers: Headers });
    }
    addTrainer(val,trainerId) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/addTrainer/${val}/${trainerId}`, { headers: Headers });
    }
    addToClass(val,event_Id, eventId) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/addToClass/${val}/${event_Id}/${eventId}`, { headers: Headers });
    }
    addNonMemberToClass(email, paymentMethod, eventId) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/addNonMemberToClass/${email}/${paymentMethod}/${eventId}`, { headers: Headers });
    }
    addToClassByPaymentMethod(memberID, eventId, paymentMethod) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/addToClassByPaymentMethod/${memberID}/${eventId}/${paymentMethod}`, { headers: Headers });
    }

    deleteEventClass(val) {
        var Headers = this.getHeaders();

        return this.http.delete(`${environment.eventUrl}/deleteEventClass/${val}`, { headers: Headers });
    }

    eventClassDetail(val) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/eventClassDetail/${val}`, { headers: Headers });
    }
    gymMembers(val, event_Id) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/gymMembers/${val}/${event_Id}`, { headers: Headers });
    }
    checkCreditCard(user_id) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/checkCreditCard/${user_id}`, { headers: Headers });
    }
    eventClassChekedIn(val) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/eventClassChekedIn/${val}`, { headers: Headers });
    }
    checkInEveryOne(val, checkInEveryOneOrUnCheckInEveryOne) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.eventUrl}/checkInEveryOne/${val}/${checkInEveryOneOrUnCheckInEveryOne}`, { headers: Headers });
    }


    


    

    getProfileEvents(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.eventUrl}/getUserProfileEventsWeb`, { headers: Headers, params: { "startDate": obj.startDate, "page": obj.page, sortKey: obj.sortKey } });
    }
    followGym(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.messageUrl}/followGym`, obj, { headers: Headers });
    }
    UnFollowGym(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.messageUrl}/UnFollowGym`, obj, { headers: Headers });
    }
    
    getGyms(gymName){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.messageUrl}/getGyms?gym=${gymName}`, { headers: Headers, params: {}}  );
    }
    getFollowingGyms(){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.messageUrl}/getFollowingGyms`, { headers: Headers}  );
    }
    getSelectedGym(){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.messageUrl}/getSelectedGym`, { headers: Headers}  );
    }
    viewGym(gymId){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.messageUrl}/viewGym`, gymId ,  { headers: Headers}  );
    }
    viewTransections(obj){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/getTransactions` ,  { headers: Headers , params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter, "subFilterValue" :  obj.subFilterValue }});
    }
    getCustomerDetail(cus_id){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.stripeModuleUrl}/getCustomerDetail` ,cus_id, { headers: Headers}  );
    }
    attendEvent(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.eventApiUrl}/attendEvent`, obj, { headers: Headers});
    }
    attendEventClass(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.eventApiUrl}/attendEventClass`, obj, { headers: Headers});
    }

    
    getFullUserDetails(id,){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/getFullUserDetails/${id}`,{ headers: Headers });
    }
    getSubscription(id,){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/getSubscription/${id}`,{ headers: Headers });
    }
    getAllSpent(id){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.eventUrl}/getAllSpent/${id}`,{ headers: Headers });
    }
    eventAttendeesList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.eventUrl}/eventAttendeesList`, { headers: Headers, params: { "startDate": obj.startDate, "sortKey": obj.filter, "search": obj.search, "page": obj.page , "searchDatestart" : obj.searchDatestart ,"searchDateEnd" : obj.searchDateEnd,"offset": obj.offset} });
    }

    stopStream(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.eventUrl}/stopStream`, obj, { headers: Headers });
    }

    allClassesList(obj) {
        var Headers = this.getHeaders();
         return this.http.post(`${environment.eventUrl}/allClassesList`,obj, { headers: Headers });
    }

    AttendeesList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.eventUrl}/AttendeesList`, { headers: Headers, params: { "startDate": obj.startDate, "sortKey": obj.filter, "search": obj.search, "page": obj.page, "eventId" : obj.eventId } });
    }

    getAllAttendeesList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.eventUrl}/getAllAttendeesList`, { headers: Headers, params: { "eventId" : obj.eventId } });
    }

    
    getAllAttendeesListV2(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.eventUrl}/getHeartRateV2`, { headers: Headers,  params: { "eventId" : obj.eventId }});
    }

    
    getUpdatedStreamUsers(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.eventUrl}/getUpdatedStreamUsers`, obj, { headers: Headers});
    }

    

    cancelBooking(val) {
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.eventUrl}/cancelBooking/${val}`, { headers: Headers });
    }

    subscriptionDateUpdate(){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/subscriptionDateUpdate/`,{ headers: Headers });
    }
}
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AdminService {
    constructor(private http: HttpClient) { }

    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        //headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    getHeadersForMultipart() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    gymList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/gymList`, { headers: Headers ,params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter } });
    }
    deletegym(id){
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.adminApiUrl}/deleteGym/${id}`, { headers: Headers });
    } 
    changestatus(obj){
        var Headers = this.getHeaders();
        return this.http.put(`${environment.adminApiUrl}/changestatusgym`, obj ,{ headers: Headers });
    }
    changestatusgymByAdmin(obj){
        var Headers = this.getHeaders();
        return this.http.put(`${environment.adminApiUrl}/changestatusgymByAdmin`, obj ,{ headers: Headers });
    }
    getUserofgym(id,obj){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/getUserofgym/${id}`, obj  );
    }
    deleteuser(id){
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.adminApiUrl}/deleteuser/${id}`, { headers: Headers });
    }
    getuser(id){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/getuser/${id}`, { headers: Headers });
    } 
    updateUser(obj){
        var Headers = this.getHeaders();
        return this.http.put(`${environment.adminApiUrl}/updateUser`, obj ,{ headers: Headers });
    }
    getAllPlans(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/planList`, { headers: Headers, params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter, "duration": obj.duration, "level": obj.level, "gym_id" : obj.gym_id } });
    }
    getCustomerAllPlan(obj,gym_id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/customerPlanList`, { headers: Headers, params:{"sortKey": obj.filter , "gym_id" : gym_id}});
    }
    getCustomerPlanDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/getCustomerPlan/${id}`, { headers: Headers });
    }
    getPayments(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/paymentsList`, { headers: Headers, params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter, "duration": obj.duration, "level": obj.level }  });
    }
    getPaymentDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/paymentDetail`, { headers: Headers, params: { id : id }  });
    }
    applicationFeePer(obj){
        var Headers = this.getHeaders();
        return this.http.put(`${environment.adminApiUrl}/applicationFeePer`, obj ,{ headers: Headers });
    }
    createNewsCategory(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.adminApiUrl}/createNewsCategory`, data, { headers: Headers });
    }
    createNewsInheritance(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.adminApiUrl}/createNewsInheritance`, data, { headers: Headers });
    }
    NewsCategoryList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/NewsCategoryList`,  { headers: Headers, params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter, "duration": obj.duration, "level": obj.level }  } );
    }
    inheriteList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/inheriteList`,  { headers: Headers, params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter, "duration": obj.duration, "level": obj.level }  } );
    }
    getNewsCategoryDetails(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/getNewsCategory/${id}`, { headers: Headers });
    }

    getInheriteDetails(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/getInheriteDetails/${id}`, { headers: Headers });
    }
    getSlaveGymsList(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.adminApiUrl}/getSlaveGymsList`,  { headers: Headers, params: { "gymId": obj.gymId}  } );
    }
    checkExistingSlaveGym(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.adminApiUrl}/checkExistingSlaveGym`,  { headers: Headers, params: { "gymId": obj.gymId}  } );
    }
    checkExistingSlaveGymWhileEdit(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.adminApiUrl}/checkExistingSlaveGymWhileEdit`,  { headers: Headers, params: { "gymId": obj.gymId}  } );
    }
    
    updateInheritance(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.adminApiUrl}/updateInheritance`, data, { headers: Headers });
    }
    
    
    updateNewsCategory(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.adminApiUrl}/updateNewsCategory`, data, { headers: Headers });
    }
    
    deleteInheritance(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.adminApiUrl}/deleteInheritance`, data, { headers: Headers });
    }
    statusChange(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.adminApiUrl}/statusChange`, data, { headers: Headers });
    }
    viewTransections(obj){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/customers` ,  { headers: Headers, params: { "search": obj.search, "page": obj.page } } );
    }
    getFullUserDetails(id,gymId){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/getFullUserDetails/${id}/${gymId}`,{ headers: Headers });
    }
    getStripeFee(id, direction){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/getStripeFee/${direction}/${id}`,{ headers: Headers });
    }
    getFeeDetails(feeId){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.adminApiUrl}/getStripeFeeDetail/${feeId}`, { headers: Headers });
    }

    
}
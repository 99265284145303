import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SharedService {

  loginVal: string;
  _comp1ValueBS = new BehaviorSubject<string>('');

  comp2Val: string;
  _comp2ValueBS = new BehaviorSubject<string>('');

  userDetail: any = [];

  constructor() {
    this.loginVal;
    this.comp2Val;

    this._comp1ValueBS.next(this.loginVal);
    this._comp2ValueBS.next(this.comp2Val);
  }

  updateLoginVal(val) {
    this.loginVal = val;
    this._comp1ValueBS.next(this.loginVal);
  }

  updateComp2Val(val) {
    this.comp2Val = val;
    this._comp2ValueBS.next(this.comp2Val);
  }

}
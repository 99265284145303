﻿import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }
    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }
    getHeadersForMultipart() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }
    
    testEmail() {
        return this.http.get(`${environment.subscriptionPlansUrl}/testEmail`);
    }
    register(user: any) {
        return this.http.post(`${environment.apiUrl}/register`, user);
    }
    addMember(user: any) {
        return this.http.post(`${environment.eventUrl}/addMember`, user);
    }
    archieveMember(id: any) {
        return this.http.post(`${environment.eventUrl}/archieveMember`, id);
    }
    login(user: any) {
        return this.http.post(`${environment.apiUrl}/login`, user);
    }
   
    testFunction() {
        return this.http.get(`${environment.apiUrl}/testFunction`);
    }
    
    forgotPassword(obj) {
        return this.http.post(`${environment.apiUrl}/forgotPassword`, obj);
    }
    resetPassword(obj) {
        return this.http.put(`${environment.apiUrl}/resetPassword`, obj);
    }
    verify(obj) {
        return this.http.put(`${environment.apiUrl}/verifyEmail`, obj);
    }
    getProfile(obj: any) {
        return this.http.post(`${environment.apiUrl}/user/find`, obj);
    }
    updateProfile(obj: any) {
        return this.http.post(`${environment.apiUrl}/user/updateUserInfo`, obj);
    }
    viewProfileWeb(id) {
        return this.http.get(`${environment.apiUrl}/viewProfileWeb/${id}`);
    }
    getGymData(obj: any) {
        return this.http.get(`${environment.apiUrl}/getGymData/${obj}`);
    }
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/viewProfile/${id}`);
    }
    uploadFile(obj: any) {
        return this.http.post(`${environment.apiUrl}/uploadFile`, obj);
    }
    completeprofile(obj: any) {
        return this.http.put(`${environment.apiUrl}/completeProfile`, obj);
    }
    updateProf(obj) {
        return this.http.put(`${environment.apiUrl}/updateProfile`, obj);
    }
    updateGymBrandColor(obj){
        return this.http.post(`${environment.apiUrl}/setGymBrandColor`, obj);
    }
    updateWeight(obj) {
        console.log(obj)
        return this.http.put(`${environment.apiUrl}/updateWeight`, obj);
    }
    regerateStripeLink(obj) {
        return this.http.post(`${environment.apiUrl}/regerateStripeLink`, obj);
    }
    connectAccount(obj) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.apiUrl}/connectAccount`, obj, { headers: Headers });
    }
    verifyPayment(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/findIntentPayment/${obj.id}`, { headers: Headers });
    }
    getUserInfo(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.apiUrl}/getUserInfo/${id}`);
    }
    reGenrateEmails(obj) {
        return this.http.put(`${environment.apiUrl}/reGenrateEmails`, obj);
    }
    getUserDetail(id){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.apiUrl}/getUserDetail/${id}`,{ headers: Headers });
    }
    addMembershipPlan(data){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/addMemberShipPlan`,data , { headers: Headers })
    }

    

    getMemberShipPlanDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.subscriptionPlansUrl}/getMemberShipPlanDetails/${id}`, { headers: Headers });
    }

    updateMembershipPlan(data){
        var Headers = this.getHeaders();
        return this.http.put(`${environment.subscriptionPlansUrl}/updateMemberShipPlan`,data , { headers: Headers })
    }

    deleteMemberShipPlan(id) {
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.subscriptionPlansUrl}/deleteMemberShipPlan/${id}`, { headers: Headers });
    }

    addCreditPackage(data){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.subscriptionPlansUrl}/addCreditPackage`,data , { headers: Headers })
    }

    updateCreditPackage(data){
        var Headers = this.getHeaders();
        return this.http.put(`${environment.subscriptionPlansUrl}/updateCreditPackage`,data , { headers: Headers })
    }

     
    changeStatusMemberShipPlan(id,status) {
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.subscriptionPlansUrl}/changeStatusMemberShipPlan/${id}/${status}`, { headers: Headers });
    }

    
    
    
    
}

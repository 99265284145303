import { Component, OnInit } from "@angular/core";
import { AuthenticationService, UserService, BrandcolorService } from "../../_services";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { environment } from "../../../environments/environment";
@Component({
  selector: "app-headers",
  templateUrl: "./headers.component.html",
  styleUrls: ["./headers.component.css"],
})
export class HeadersComponent implements OnInit {
  s3url = environment.S3Url;
  isShow = false;
  userName = "";
  firstWord = "";
  gymid: any;
  logo = "";
  coverPic = "";
  user: any;
  profileimg: any;
  currentUserSubscription: any;
  currentUser: any;
  data: any;
  loadimage: any;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) {
    // this.getData();
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      (user) => {
        this.currentUser = user;
      }

    );
  }

  ngOnInit() {

    this.getGymInfo();
    console.log("header is  working.....");
    this.userService.getUserDetail(this.currentUser._id).subscribe((res) => {
      this.userName = res["data"].firstName;
      this.gymid = res["data"]["_id"];
      console.log(this.gymid);
      this.getData();
      this.firstWord = res["data"].firstName.substring(0, 1).toUpperCase();
    });
  }
  getGymInfo() {
    this.loadimage = false;
    this.userService
      .viewProfileWeb(this.authenticationService.currentUserValue["_id"])
      .pipe(first())
      .subscribe(
        (res) => {
          this.data = res["data"][0];
          this.profileimg = this.data.profilePic;
          this.loadimage = true;
        },
        (error) => {
          // this.loading = false;
          // this.error = error;
        }
      );
  }
  getData() {
    console.log(this.gymid);

    this.userService
      .getGymData(this.gymid)
      .pipe(first())
      .subscribe(
        (res) => {
          console.log('Blue bird', res);
          this.user = "https://vexfitnessuploads.s3.us-west-1.amazonaws.com/" + res["data"].logo;
          var existing = localStorage.getItem("currentUser");
          existing = existing ? JSON.parse(existing) : {};
          existing["gym"][0] = res["data"];
          localStorage.setItem("currentUser", JSON.stringify(existing));

          let localUserObject = JSON.parse(localStorage.getItem('currentUser'));
          console.log('Hello------------------------', localUserObject, localUserObject.gym[0].brandColor)
          const bodyStyles = document.body.style;
          if (!localUserObject.gym[0].brandColor) {
            bodyStyles.setProperty('--custom-background-color', '#000');
            bodyStyles.setProperty('--custom-calender-link-color', '#000')
            bodyStyles.setProperty('--custom-footer-follow-box-button-color', '#000')
          }
          else {
            bodyStyles.setProperty('--custom-background-color', localUserObject.gym[0].brandColor);
            bodyStyles.setProperty('--custom-calender-link-color', localUserObject.gym[0].brandColor)
            bodyStyles.setProperty('--custom-footer-follow-box-button-color', localUserObject.gym[0].brandColor)
          }

        },
        (error) => { }
      );
  }

  toggle() {
    this.isShow = !this.isShow;
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/"]);
  }

  profile() {
    this.isShow = !this.isShow;
    this.router.navigate(["/customers/userProfile"]);
  }
}

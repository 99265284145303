﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models';
import {BrandcolorService} from './brandcolor.service';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private user = new BehaviorSubject<any>('');
    castUser = this.user.asObservable();
    //public castUser: Observable<any>;

    constructor(private brandcolorService: BrandcolorService, private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    currentLogoValue(newUser) {
        this.user.next(newUser);
    }

    login(email: string, password: string, roleId: string, deviceType: string, username: string) {
       let obj; 
        if( roleId == 'gym' || roleId == 'user' ){
            if( roleId == 'gym'){
                obj = {
                email: email,
                password: password,
                userRole: roleId,
                deviceType: deviceType,
                businessId: username
                }
            } else if (roleId == 'user') {
                obj = { 
                    email: email,
                    password: password,
                    userRole: roleId,
                    deviceType: deviceType
                }
            }
            return this.http.post<any>(`${environment.apiUrl}/login`, obj
            )
                .pipe(map(user => {
                    // login successful if there's a jwt token in the response
                    if (user && user.data && user.data.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user.data));
                         localStorage.setItem('role', roleId);
                        this.currentUserSubject.next(user.data);
                  
                    }
                
                    return user.data;
                }));
        }
        else{
            obj = { 
                email: email,
                password: password,
                userRole: 'admin',
                deviceType: deviceType
            }
            return this.http.post<any>(`${environment.apiUrl}/loginAdmin`, obj
            )
                .pipe(map(user => {
                    // login successful if there's a jwt token in the response
                    if (user && user.data && user.data.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user.data));
                        localStorage.setItem('role', roleId);
                        this.currentUserSubject.next(user.data);
                    }
                
                    return user.data;
                }));
        }
    }
    autologin(_id: string) {
        let obj; 
       
             
                 obj = { 
                     _id: _id
                 }
             
             return this.http.post<any>(`${environment.apiUrl}/autologin`, obj
             )
                 .pipe(map(user => {
                     // login successful if there's a jwt token in the response
                     if (user && user.data && user.data.token) {
                         // store user details and jwt token in local storage to keep user logged in between page refreshes
                         localStorage.setItem('currentUser', JSON.stringify(user.data));
                         localStorage.setItem('role', 'user');
                         this.currentUserSubject.next(user.data);
                     }
                 
                     return user.data;
                 }));
         
     
     }


    registerauth(user) {
        if (user && user.data && user.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.data));
            localStorage.setItem('role', user.data.roleId);
            this.currentUserSubject.next(user.data);
            if (user.data['userRole'] && user.data['userRole'] == 'user') {
                this.user.next(user.data['gym'][0]['logo']);
            }
        }
        return user.data;
    }

    logout() {
        // remove user from local storage to log user out
        let u = JSON.parse(localStorage.getItem('currentUser'));
        if (u && u['userRole'] && u['userRole'] != null && u['userRole'] == 'user') {
            this.user.next(null);
        }
        const bodyStyles = document.body.style;
        bodyStyles.setProperty('--custom-background-color',"#000");
        bodyStyles.setProperty('--custom-calender-link-color',"#000")
        bodyStyles.setProperty('--custom-footer-follow-box-button-color',"#000")
        localStorage.removeItem('currentUser');
        localStorage.removeItem('role');
        this.currentUserSubject.next(null);
        
    }

}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import * as Rx from "rxjs/Rx";

@Injectable()
export class WebsocketService {
  constructor(private http: HttpClient) {
    // this.createToken();
  }

  getHeaders() {
    var accessToken = localStorage.getItem('token');
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('authorization', 'bearer ' + accessToken);
    return headers;
}

  private subject: Rx.Subject<MessageEvent>;

  public connect(url): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log("Successfully connected: " + url);
    }
    return this.subject;
  }

  private create(url): Rx.Subject<MessageEvent> {
    let ws = new WebSocket(url);

    let observable = Rx.Observable.create((obs: Rx.Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    let observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({"op":3,"d":{"token":"dmV4LWZpdG5lc3MtZGV2LTlmRVNmeTJRZ1E.uJ9E9ee8VfnNUURUjLtvJW9oMiW1Wr9WkiZeiW4NqPw","type":1}}));

          const generateToken = new Promise((resolve, reject) => {
            const options = {
                method: 'POST', 
                headers: {
                    Accept: 'application/json',
                    "dev-id": environment.terraDevId,
                    "X-API-Key": environment.terraXAPIKey
                }
            };


        
            return fetch('https://ws.tryterra.co/auth/developer', options)
              .then(response => resolve(response.json()))
              .catch(err => reject(console.error(err)));
        })
        
        console.log(generateToken['token']);
          


        }
      }
    };
    return Rx.Subject.create(observer, observable);
  }


  createToken() {
    var Headers = this.getHeaders();
    return this.http.post(`${environment.eventApiUrl}/getToken`, {}, { headers: Headers })
  }


}

import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class GymService {
    constructor(private http: HttpClient) { }

    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        //headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    getHeadersForMultipart() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    searchBusinessName(val) {
        var Headers = this.getHeaders();
        var v=decodeURI(val);
        console.log("v--->",v);
        console.log("val--->",val);
        if(val == "")
        {
            val = "--";
        }
        return this.http.get(`${environment.gymApiUrl}/businessNameList/${decodeURI(v)}`, { headers: Headers });
    }
    searchProfileBusinessName(val) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.gymApiUrl}/businessProfileNameList/${val}`, { headers: Headers });
    }

    checkBusinesses(obj: any) {
        var Headers = this.getHeaders();

        return this.http.put(`${environment.gymApiUrl}/checkBusinessName`, obj, { headers: Headers });
    }

    getGymInfo(id: any) {
        var Headers = this.getHeaders();

        return this.http.get(`${environment.gymApiUrl}/getGym/${id}`, { headers: Headers });
    }
    updateProfile(obj) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.gymApiUrl}/updateGym`, obj, { headers: Headers });
    }
    getStripeCustomers() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/getCustomers`, { headers: Headers });
    }
    getAllgymList() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.gymApiUrl}/businessNameListAll`, { headers: Headers });
    }
    updateGymSetting(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.eventApiUrl}/setGymSetting`, obj, { headers: Headers });
    }
    appDeeplink(){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.eventApiUrl}/appDeeplinks`, { headers: Headers });
    }
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class PlanService {
    constructor(private http: HttpClient) { }

    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    getHeadersForMultipart() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    create(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/create`, data, { headers: Headers });
    }

    addPdf(data) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/addPdf`, data, { headers: Headers });
    }

    getPlanDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.planUrl}/getPlan/${id}`, { headers: Headers });
    }
    getCustomerPlanDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.planUrl}/getCustomerPlan/${id}`, { headers: Headers });
    }
    
    createWeek(data) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/addWeekData`, data, { headers: Headers });
    } 
    

    getAllPlans(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.planUrl}/planList`, { headers: Headers, params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter, "duration": obj.duration, "level": obj.level } });
    }
    getCustomerAllPlan(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.planUrl}/customerPlanList`, { headers: Headers, params:{"sortKey": obj.filter}});
    }
    updatePlan(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.planUrl}/updatePlan`, data, { headers: Headers });
    }
    finishPlan(obj) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.planUrl}/finishPlan`, obj, { headers: Headers });
    }
    
    deletePlan(id) {
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.planUrl}/deletePlan/${id}`, { headers: Headers });
    }

    statusChange(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.planUrl}/statusChange`, data, { headers: Headers });
    }

    subscribePlan(data) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/subscribePlan`, data, { headers: Headers });
    }

    GetsubscribePlanGym() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.planUrl}/GetGymPlans`, { headers: Headers });
    }
    GetplanGym(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.planUrl}/GetSingleGymPlan/${id}`, { headers: Headers });
    }
    UpdatesubscribePlanGym(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.planUrl}/UpdateGymPlans`,data ,{ headers: Headers });
    }
    UpdateSubscriptionMenus(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.planUrl}/UpdateSubscriptionMenus`,data ,{ headers: Headers });
    }
    Getcurrency() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.planUrl}/GetCurrency`, { headers: Headers });
    }
    updateCurrency(data){
        var Headers = this.getHeaders();
        return this.http.put(`${environment.planUrl}/updateCurrency`,data ,{ headers: Headers });
    }
    UpdatePlanDescription(data){
        var Headers = this.getHeaders();
        return this.http.put(`${environment.planUrl}/updatePlanDescription`,data ,{ headers: Headers })
    }
    switchOnOffSubscriptionPlans(data){
        var Headers = this.getHeaders();
        return this.http.put(`${environment.planUrl}/switchOnOffSubscriptionPlans`,data ,{ headers: Headers })
    }
    
    getStripeActInformation(data){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/getStripeActInformation`,data , { headers: Headers })
    }
    addPlanPdfs(data){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/addPlanPdfs`,data , { headers: Headers })
    }
    listPlanPdfs(data){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/listPlanPdfs`,data , { headers: Headers })
    }
    addPlanVideos(data){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/addPlanVideos`,data , { headers: Headers })
    }
    listPlanVideos(data){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/listPlanVideos`,data , { headers: Headers })
    }
    removePdf(id) {
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.planUrl}/removePdf/${id}`, { headers: Headers });
    }
    
    GetpdfDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.planUrl}/GetpdfDetail/${id}`, { headers: Headers });
    }
    updatePlanPdfs(obj){
        var Headers = this.getHeaders();
        return this.http.post(`${environment.planUrl}/updatePlanPdfs`,obj , { headers: Headers })
    }
    removePlanVideos(id){
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.planUrl}/removePlanVideos/${id}`, { headers: Headers });
    }


   
}

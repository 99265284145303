import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"],
})
export class ContactUsComponent implements OnInit {
  constructor() {}
  ngAfterViewInit() {
    hbspt.forms.create({
      portalId: "7487499",
      formId: "d258e959-9d8c-4812-bac8-4611c17be2cf",
      target: "#hubspotForm",
    });
  }

  ngOnInit() {}
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class TrainerService {
    constructor(private http: HttpClient) { }

    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    getHeadersForMultipart() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    create(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.trainerUrl}/create`, data, { headers: Headers });
    }

    getTrainerList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.trainerUrl}/trainerList`, { headers: Headers, params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter } });
    }

    getAllActiveTrainerList() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.trainerUrl}/getAllActiveTrainerList`, { headers: Headers});
    }

    

    getTrainerDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.trainerUrl}/getTrainer/${id}`, { headers: Headers });
    }
    
    updateTrainer(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.trainerUrl}/updateTrainer`, data, { headers: Headers });
    }

    deleteTrainer(id) {
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.trainerUrl}/deleteTrainer/${id}`, { headers: Headers });
    }

    statusChange(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.trainerUrl}/statusChange`, data, { headers: Headers });
    }

    getTrainer() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.trainerUrl}/getTrainerList`, { headers: Headers });
        //return this.http.get(`${environment.trainerUrl}/getTrainerList`, { headers: Headers, params: { "search": search } });
    }

    webTrainer(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.trainerUrl}/getCustTrainerList`, { headers: Headers, params: { "page": obj.page } });  
    }

    createMessage(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.messageUrl}/create`, data, { headers: Headers });
    }

    getmessageList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.messageUrl}/messageList`, { headers: Headers, params: { "page": obj.page , "search" : obj.search } });  
    }
    getmessageDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.messageUrl}/getMessage/${id}`, { headers: Headers });
    }
    deletemessage(id){
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.messageUrl}/deleteMessage/${id}`, { headers: Headers });        
    }
    updatemessage(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.messageUrl}/updateMessage`, data, { headers: Headers });
    }
    getsubscriptionList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.messageUrl}/subscriptionList`, { headers: Headers, params: { "page": obj.page , "search" : obj.search } });  
    }
    getNewsCategoryList() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/NewsCategoryList`, { headers: Headers } );  
    }    
    createFeeds(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.stripeModuleUrl}/createFeeds`, data, { headers: Headers });
    }
    getFeedsList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/NewsFeedList`, { headers: Headers, params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter } });
    }
    updateFeed(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.stripeModuleUrl}/updateFeed`, data, { headers: Headers });
    }
    getFeedDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/getFeedDetail/${id}`, { headers: Headers });
    }
    changesFeedStatus(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.stripeModuleUrl}/changesFeedStatus`, data, { headers: Headers });
    }   
    deleteFeed(id) {
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.stripeModuleUrl}/deleteFeed/${id}`, { headers: Headers });
    }
    getFrontEndFeedsList(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.stripeModuleUrl}/GetNewsFeedList`, { headers: Headers, params: { "categoryId": obj.categoryId, "sortKey": obj.filter } });
    }
}

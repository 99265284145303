import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class VideoService {
    constructor(private http: HttpClient) { }

    getHeaders() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    getHeadersForMultipart() {
        var accessToken = localStorage.getItem('token');
        var headers = new HttpHeaders();
        headers.append('authorization', 'bearer ' + accessToken);
        return headers;
    }

    create(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.videoUrl}/create`, data, { headers: Headers });
    }

    getAllVideos(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.videoUrl}/videoList`, { headers: Headers, params: { "search": obj.search, "page": obj.page, "sortKey": obj.filter, "intensity": obj.intensityFilter, "category": obj.category, "dateFilter": obj.dateFilter } });
    }

    getVideoDataEdit(id){
        var Headers = this.getHeaders();
        return this.http.get(`${environment.videoUrl}/getVideoDataEdit/${id}`, { headers: Headers });
    }


    getVideoDetail(id) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.videoUrl}/getVideoDataEdit/${id}`, { headers: Headers });
    }

    updateVideo(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.videoUrl}/updateVideo`, data, { headers: Headers });
    }

    deleteVideo(id) {
        var Headers = this.getHeaders();
        return this.http.delete(`${environment.videoUrl}/deleteVideo/${id}`, { headers: Headers });
    } 

    statusChange(data) {
        var Headers = this.getHeaders();
        return this.http.put(`${environment.videoUrl}/statusChange`, data, { headers: Headers });
    }

    getCatVideos(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.videoUrl}/getCategoriesVideos`, { headers: Headers, params: { "id": obj.id, "page": obj.page, "intensity": obj.intensity, "length": obj.length, "dateFilter": obj.dateFilter, "trainer": obj.trainer } });
    }

    likeUnlike(data: any) {
        var Headers = this.getHeaders();
        return this.http.post(`${environment.videoUrl}/likeUnlikeVideo`, data, { headers: Headers });
    }

    getLatestVideo() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.videoUrl}/getLatestVideos`, { headers: Headers });
    }
    getFavouriteVideos() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.videoUrl}/getFavouriteVideos`, { headers: Headers });
    }
    

    trainerVideos(obj) {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.videoUrl}/getTrainersVideos`, { headers: Headers, params: { "id": obj.id, "page": obj.page, "intensity": obj.intensity, "length": obj.length, "dateFilter": obj.dateFilter } });  
    }

    getVideoListPlan() {
        var Headers = this.getHeaders();
        return this.http.get(`${environment.videoUrl}/getVideosList`, { headers: Headers });    
    }
    decreaseCredits(obj){
        return this.http.post(`${environment.videoUrl}/decreaseCredits`, obj)
    }
    increaseCredits(obj){
        return this.http.post(`${environment.videoUrl}/increaseCredits`, obj)
    }
    switchFreeSubscription(obj){
        return this.http.post(`${environment.videoUrl}/switchFreeSubscription`, obj)
    }
}
